import React, {useEffect, useState} from "react";
import { useDispatch, useSelector } from "react-redux";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

import Imput from "../Imput";
import { updateTablaPedido } from "../../../actions/tablaPedido";

export default function TableGrid(props) {
  const tabla = useSelector((state) => state.tablaPedido.tablaPedido);
  const { productos, idProveedor, totalPedido, changePedido } = props;
  
  const dispatch = useDispatch();
  const [total,setTotal]= useState(0)

  const handleImputCantidad=(event, id)=>{
    dispatch(updateTablaPedido({
        id:id,       
        cantidad: event.target.value,
        idProveedor:idProveedor
      })
    );
  }

  const handleImputCambio=(event, id)=>{
    dispatch(updateTablaPedido({
      id:id,       
      cambio: event.target.value,
      idProveedor:idProveedor
    })
  );
  }

  useEffect(() => {
    
    let cantidad = 0
    for (let obj in tabla) { 
      for (let produc in tabla[obj].pro){
          cantidad+= (tabla[obj].pro[produc].cantidad * tabla[obj].pro[produc].precio)-(tabla[obj].pro[produc].cambio < 0 ? (tabla[obj].pro[produc].cambio * tabla[obj].pro[produc].precio) * -1 : 0)
      }    
    } 
    changePedido(cantidad)
    
  }, [tabla]);
  
  return (
    <div>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Producto</TableCell>
              <TableCell align="center">Cantidad</TableCell>
              <TableCell align="center">Cambio</TableCell>
              <TableCell align="center">Precio</TableCell>
              <TableCell align="center">SubTotal</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {productos?.map((row) => (
              <TableRow
                key={row.id}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell
                  component="th"
                  scope="row"
                  size="medium"
                  sx={{ minWidth: 0 }}
                >
                  {row.nombre}
                </TableCell>
                <TableCell align="center">
                  <Imput  name="cantidad"  change={handleImputCantidad} value={tabla[idProveedor].pro[row.id].cantidad} id={row.id} min="1" />
                </TableCell>
                <TableCell align="center">
                  <Imput name="cambio"  change={handleImputCambio} value={tabla[idProveedor].pro[row.id].cambio} id={row.id} />
                </TableCell>
                <TableCell align="center">${row.precio}</TableCell>
                <TableCell align="center">{(row.precio * tabla[idProveedor].pro[row.id].cantidad) - (tabla[idProveedor].pro[row.id].cambio < 0 ? (tabla[idProveedor].pro[row.id].cambio * row.precio) * -1 : 0)}</TableCell>
              </TableRow> 
            ))}
            <TableRow 
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
            <TableCell align="center"></TableCell>
            <TableCell align="center"></TableCell>
            <TableCell align="center"></TableCell>
            <TableCell lign="center"><p style={{display: "flex", justifyContent: "center"}}>TOTAL :</p></TableCell>
            <TableCell lign="center"><p style={{display: "flex", justifyContent: "center"}}>{totalPedido}</p></TableCell>
            
            </TableRow>
            
          </TableBody>
          
        </Table>
      </TableContainer>
    </div>
  );
}
