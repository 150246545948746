import { actionTypes } from '../constants/ActionTypes'

export const agregarProducto = (data) => {

    return {
        type: actionTypes.AGREGAR_UN_PRODUCTO,
        data
    }
}

export const setTablaPedido = (data) =>{

    return {
        type: actionTypes.SET_TABLA_PEDIDO,
        data
    }
}

export const updateTablaPedido = (data) =>{

    return {
        type: actionTypes.UPDATE_TABLA_PEDIDO,
        data
    }
}

export const setCheckTablaPedido = (data) =>{

    return {
        type: actionTypes.SET_CHECK_TABLA_PEDIDO,
        data
    }
}

export const setAllCheckTablaPedido = (data) =>{

    return {
        type: actionTypes.SET_ALL_TABLA_PEDIDO,
        data
    }
}


