import { actionTypes } from "../constants/ActionTypes";
import { takeEvery, call, put, take } from 'redux-saga/effects';

import { getClientes, setClientes } from "../actions/clientes";
import { getAddCliente, getCliente, getUpdateCliente } from "../api/cliente";

export const watchCliente = function * (){
    yield takeEvery(actionTypes.FETCH_GET_CLIENTES, fetchClientes)
    yield takeEvery(actionTypes.ADD_CLIENTE, fetchAddClientes)
    yield takeEvery(actionTypes.MODIFICAR_CLIENTE, fetchUpdateClientes)
    
}

function* fetchClientes(action){
    try{
        const data = yield call(getCliente, action.data);
        if(data){
            yield put(setClientes(data));
        }
    }catch(err){
        console.log(err);
        // toast.error('¡Revise los campos!', {
        // });
    }
}

function* fetchAddClientes(action){
    try{
        const data = yield call(getAddCliente, action.data);
        if(data){
            yield put(getClientes());
            yield take("SET_CLIENTES");
        }
    }catch(err){
        console.log(err);
        // toast.error('¡Revise los campos!', {
        // });
    }
}

function* fetchUpdateClientes(action){
    try{
        const data = yield call(getUpdateCliente, action.data);
        if(data){
            yield put(getClientes());
            yield take("SET_CLIENTES");
        }
    }catch(err){
        console.log(err);
        // toast.error('¡Revise los campos!', {
        // });
    }
}