import React, { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import "./pedido.css";

import { useDispatch, useSelector } from "react-redux";

import Table from "../../Components/common/Table/TableGrid";
import { getEnviarPedido } from "../../actions/pedido";
import { setLoader } from "../../actions/loader";

import OutlinedInput from "@mui/material/OutlinedInput";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";

import {PDFDownloadLink} from '@react-pdf/renderer'
import PDF from '../../Components/common/Pdf'
import { getClientes } from "../../actions/clientes";
import { getMarcas } from "../../actions/marca";
import { setTablaPedido } from "../../actions/tablaPedido";


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

export default function Pedido() {
    const proveedores = useSelector((state) => state.marca.marcas);
    const pedidos = useSelector((state) => state.pedidos);
    const pedidoStore = useSelector((state) => state.tablaPedido);
    const clientes = useSelector((state) => state.cliente);
    const loader = useSelector((state) => state.loader);

    const dispatch = useDispatch();
    const [stateProveedor, setStateProveedor] = useState();
    const [totalPedido, setTotalPedido] = useState();
    const [imageSrc, setImageSrc] = useState(null);

    const [personName, setPersonName] = useState([]);

    let listProveedores = [];

    useEffect(() => {
        dispatch(getMarcas());
        dispatch(getClientes());
    }, []);

    useEffect(() => {
        if (proveedores) {
            dispatch(setTablaPedido(proveedores.Proveedores));
        }
    }, [proveedores]);

    const handleChange = (event) => {
        const {
            target: { value },
        } = event;
        setPersonName(
            // On autofill we get a stringified value.
            // typeof value === 'string' ? value.split(',') : value,
            event.target.value
        );
    };

    const handleProducto = (event) => {
        let positionArray = proveedores.Proveedores.find(
            (item) => item.id == event.target.value
        );

        if (positionArray !== null) {
            setStateProveedor(positionArray);
        } else {
            setStateProveedor(null);
        }
    };

    const handleFinalizar = () => {
        dispatch(setLoader(true));
        let formData = new FormData();

        formData.append("pedido", JSON.stringify(pedidoStore));
        formData.append("cliente", personName);
        formData.append("total", totalPedido);

        dispatch(getEnviarPedido(formData));
    };

    useEffect(() => {
        
      }, []);

    return (
        <div className="content">
            <div className="body">
                    <div  className="celular-pedido">
                        <h5
                            style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                padding: "0px 0px 4px 5px",
                            }}
                        >
                            Clientes :
                        </h5>
                        <FormControl sx={{ m: 1, width: 300, mt: 1 }}>
                            <Select
                                style={{ background: "white" }}
                                displayEmpty
                                value={personName}
                                onChange={handleChange}
                                input={<OutlinedInput />}
                                MenuProps={MenuProps}
                                inputProps={{ "aria-label": "Without label" }}
                            >
                                <MenuItem
                                    style={{ background: "white !important" }}
                                    disabled
                                    value=""
                                >
                                    <em>Clientes</em>
                                </MenuItem>
                                {clientes.cliente?.data != null ? clientes.cliente.data.map((item) => (
                                    <MenuItem key={item.id} value={item.id}>
                                        {item.nombre}
                                    </MenuItem>
                                )) : "cargando..."}
                            </Select>
                        </FormControl>
                    
                    
                        <h5
                            style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                padding: "0px 0px 4px 5px",
                            }}
                        >
                            Marca :
                        </h5>
                        <FormControl sx={{ m: 1, width: 300, mt: 1 }}>
                            <Select
                                style={{ background: "white" }}
                                displayEmpty
                                value={ stateProveedor ? stateProveedor.id : []  }
                                onChange={handleProducto}
                                input={<OutlinedInput />}
                                MenuProps={MenuProps}
                                inputProps={{ "aria-label": "Without label" }}
                            >
                                <MenuItem
                                    style={{ background: "white !important" }}
                                    disabled
                                    value=""
                                >
                                    <em>Marca</em>
                                </MenuItem>
                                {proveedores?.Proveedores != null ? proveedores.Proveedores?.map((item) => (
                                    <MenuItem key={item.id} value={item.id}>
                                        {item.nombre}
                                    </MenuItem>
                                )) : ""}
                            </Select>
                        </FormControl>
                    </div>
                
                {loader.loader == true ? (
                    <div style={{ display: "flex", justifyContent: "center" }}>
                        <Box sx={{ display: "flex" }}>
                            <CircularProgress />
                        </Box>
                    </div>
                ) : (
                    <>
                        <Table
                            productos={
                                stateProveedor ? stateProveedor.productos : []
                            }
                            idProveedor={stateProveedor?.id}
                            totalPedido={totalPedido}
                            changePedido={setTotalPedido}
                        />
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "end",
                                padding: "5px 0px 4px 5px",
                            }}
                        >
                            {stateProveedor &&
                                personName.length != 0 &&
                                totalPedido > 0 && (
                                    <>
                                    <div>
                                    <Button
                                        variant="secondary"
                                        style={{ marginRight: "5px" }}
                                        onClick={handleFinalizar}
                                    >
                                        Finalizar
                                    </Button>
                                    </div>
                                    <div>
                                    <PDFDownloadLink document={<PDF pedidos={ pedidoStore }  cliente={clientes && clientes.cliente.data.find((element) => element.id == personName)} totalPedido={totalPedido} image={imageSrc}/>} fileName="archivo.pdf">
                                     {({ blob, url, loading, error }) => (
                                            loading ? (
                                                <Button variant="danger" >descargando Pdf</Button>
                                            ) : (
                                                <Button variant="danger" >descargar PDF</Button>
                                            )
                                        )}
                                    </PDFDownloadLink>
                                    </div>
                                    </>
                                )}
                        </div>
                    </>
                )}
            </div>
        </div>
    );
}
