export const actionTypes = {
    FETCH_GET_LOGIN: "FETCH_GET_LOGIN",
    SET_LOGIN: "SET_LOGIN",

    FETCH_GET_PROVEEDORES: "FETCH_GET_PROVEEDORES",
    SET_PROVEEDORES: "SET_PROVEEDORES",
    ADD_PROVEEDORES:"ADD_PROVEEDORES",
    UPDATE_PROVEEDORES:"UPDATE_PROVEEDORES",

    FETCH_GET_MARCAS: "FETCH_GET_MARCAS",
    SET_MARCAS: "SET_MARCAS",
    ADD_MARCA:"ADD_MARCA",
    UPDATE_MARCA:"UPDATE_MARCA",

    BUSCAR_PRODUCTO_F : 'BUSCAR_PRODUCTO_F',
    RESULT_BUSQUEDA : 'RESULT_BUSQUEDA',
    
    FETCH_GET_CLIENTES: 'FETCH_GET_CLIENTES',
    SET_CLIENTES: 'SET_CLIENTES',
    ADD_CLIENTE: 'ADD_CLIENTE',
    MODIFICAR_CLIENTE: 'MODIFICAR_CLIENTE',
    ELIMINAR_CLIENTE: 'ELIMINAR_CLIENTE',

    AGREGAR_UN_PRODUCTO: 'AGREGAR_UN_PRODUCTO',
    MODIFICAR_PRODUCTO: 'MODIFICAR_PRODUCTO',
    ELIMINAR_PRODUCTO: 'ELIMINAR_PRODUCTO',

    SET_TABLA_PEDIDO:"SET_TABLA_PEDIDO",
    UPDATE_TABLA_PEDIDO:"UPDATE_TABLA_PEDIDO",
    SET_CHECK_TABLA_PEDIDO:"SET_CHECK_TABLA_PEDIDO",
    SET_ALL_TABLA_PEDIDO:"SET_ALL_TABLA_PEDIDO",

    FETCH_GET_ENVIAR_PEDIDO:"FETCH_GET_ENVIAR_PEDIDO",
    FETCH_PEDIDOS: 'FETCH_PEDIDOS',
    FETCH_SET_PEDIDOS: 'FETCH_SET_PEDIDOS',
    SET_LOADER:'SET_LOADER',
    SET_NOTIFICACION:'SET_NOTIFICACION',
    CLEAR_NOTIFICACION:'CLEAR_NOTIFICACION',

}