import { actionTypes } from "../constants/ActionTypes"
import {store} from '../store/index'

function reducerBusqueda(state = store.busqueda, action) {
    switch (action.type) {
      case actionTypes.RESULT_BUSQUEDA:
        let xBusqueda = state.busqueda
        xBusqueda =  action.data

        return Object.assign({}, state, {
            busqueda_filtro: xBusqueda,
        })
       
      case actionTypes.MODIFICAR_RESTRICCION:
        let xRestriccion = state.busqueda
        xRestriccion = action.data
        
        return Object.assign({}, state, {
          busqueda_restric: xRestriccion,
        })

        case actionTypes.LIMPIAR_BUSQUEDA:
        
        
        return Object.assign({}, state, {
          busqueda_filtro: null,
        })
      default:
        return state;
    }
  }

  export default reducerBusqueda;