import React from 'react'
import { BsInstagram ,BsWhatsapp,BsFacebook } from "react-icons/bs";
import './footer.css'

export default function Footer() {
  return (
    <div className='footer-contenido'>
      <div className='body-footer'>
        <div className='footer-redes'>
          <h1> <BsFacebook /></h1>
          <h1> <BsInstagram /></h1>
          <h1> <BsWhatsapp /></h1>
        </div>
       
        <div className='text-footer'>
          <p>
          </p>
        </div>
    </div>
   </div>
  )
}
