import { actionTypes } from "../constants/ActionTypes";

export function getMarcas(data) {
    return {
        type: actionTypes.FETCH_GET_MARCAS,
        data,
    };
}

export function setMarcas(data){
    return{
        type: actionTypes.SET_MARCAS,
        data,
    }
}

export function agregarMarca(data){
    return{
        type: actionTypes.ADD_MARCA,
        data,
    }
}

export function modificarMarca(data){
    return{
        type: actionTypes.UPDATE_MARCA,
        data,
    }
}