import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Col, Card } from "react-bootstrap";
import { Form, Button } from "react-bootstrap";
import Tabla from "./TablaABM";
import { buscarProductoF } from "../../actions/buscadorProducto";
import Paginator from "react-hooks-paginator";
import Modal from "./ModalAgregar";
import { getProveedores } from "../../actions/proveedores";

import OutlinedInput from "@mui/material/OutlinedInput";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import { getMarcas } from "../../actions/marca";
import "./productos.css"

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

function Abm() {
    const data = useSelector((state) => state.reducerBusqueda);
    const proveedores = useSelector((state) => state.marca.marcas);

    const dispatch = useDispatch();
    const [proveedor, setProveedor] = useState([]);
    const [modalAgregar, setModalAgregar] = useState(null);
    const [busqueda, setBusqueda] = useState({
        busquedaDato: "",
    });

    const [stateProductos, setStateProductos] = useState([]);
    const [boton, setBoton] = useState(false);
    const [offset, setOffset] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);

    let listProveedores = [];

    const handleProveedor = (event) => {
        let positionArray = proveedores.Proveedores?.filter(
            (item) => item.id == event.target.value
        );
        setProveedor(positionArray);
        if (positionArray[0].productos !== null) {
            setStateProductos(positionArray[0].productos);
        } else {
            setStateProductos(null);
        }
    };

    proveedores?.Proveedores?.forEach((element, index) => {
        listProveedores.push(
           
                element
           
        );
    });

    const imputDatos = (e) => {
        setBusqueda({
            ...busqueda,
            [e.target.name]: e.target.value,
        });
    };

    const enviarDatos = (e) => {
        setBoton(!boton);
    };

    useEffect(() => {
        const datos = {
            pag: currentPage,
            filter: busqueda.busquedaDato,
        };
        dispatch(buscarProductoF(datos));
        if(proveedores==null){
            dispatch(getMarcas());
        }
        setStateProductos(proveedores?.Proveedores[0]?.productos);
    }, []);

    useEffect(() => {
        if (data.busqueda_filtro != null) {
            const datos = {
                pag: currentPage,
                filter: busqueda.busquedaDato,
            };
            dispatch(buscarProductoF(datos));
        }
    }, [currentPage]);

    useEffect(() => {
        if (boton != false) {
            const datos = {
                pag: currentPage,
                filter: busqueda.busquedaDato,
            };
            if (busqueda.busquedaDato.length > 0) {
                dispatch(buscarProductoF(datos));
                setBoton(false);
            }
        }
    }, [boton, busqueda]);

    useEffect(() => {
     setStateProductos(proveedores?.Proveedores[0]?.productos)
  }, [proveedores]);

    return (
        <div className="content">
            <div className="body">
            <Card>
                <Card.Header>
                    <Card.Title as="h5">ABM Producto</Card.Title>
                    <div className="celular-abm">
                        <Col xs={7}>
                            <Form.Control
                                onChange={imputDatos}
                                value={busqueda.busquedaDato}
                                name="busquedaDato"
                                type="text"
                                placeholder=" Ingrese Producto"
                            />
                        </Col>
                        <Form.Group>
                            <Col>
                                <Button onClick={enviarDatos}>Buscar</Button>
                                <Button
                                    variant="primary"
                                    type="button"
                                    onClick={() => setModalAgregar(true)}
                                >
                                    +
                                </Button>
                            </Col>
                        </Form.Group>
                    </div>
                    <Form.Group>
                        <Col style={{ display: "flex" }}>
                            <h5
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                }}
                            >
                                Marcas :
                            </h5>
                            <FormControl sx={{ m: 1, width: 300, mt: 1 }}>
                                <Select
                                    style={{ background: "white" }}
                                    displayEmpty
                                    value={proveedor.length > 0 ? proveedor[0].id : proveedor}
                                    onChange={handleProveedor}
                                    input={<OutlinedInput />}
                                    MenuProps={MenuProps}
                                    inputProps={{ "aria-label": "Without label" }}
                                >
                                    <MenuItem
                                        style={{ background: "white !important" }}
                                        disabled
                                        value=""
                                    >
                                        <em>Marca</em>
                                    </MenuItem>
                                    { listProveedores.map((item) => (
                                        <MenuItem key={item.id} value={item.id}>
                                            {item.nombre}
                                        </MenuItem>
                                    )) }
                                </Select>
                            </FormControl>
                        </Col>
                    </Form.Group>
                </Card.Header>
                <Card.Body>
                    {data.busqueda_filtro != null || stateProductos != null ? (
                        <div>
                            <div>
                                <Tabla
                                    proveedor={
                                        proveedor.length > 0 ? proveedor[0].id : 1
                                    }
                                    productos={stateProductos}
                                    pagina={currentPage}
                                    filter={busqueda.busquedaDato}
                                ></Tabla>
                            </div>
                        </div>
                    ) : (
                        "Ingrese busqueda"
                    )}

                    {/* {<pre>{JSON.stringify(data1.productos,null,2)}</pre>} */}
                    {data.busqueda_filtro != null ? (
                        <div>
                            <Paginator
                                totalRecords={data.busqueda_filtro.total}
                                pageLimit={3}
                                pageNeighbours={1}
                                setOffset={setOffset}
                                currentPage={currentPage}
                                setCurrentPage={setCurrentPage}
                            />
                        </div>
                    ) : (
                        "no hay paginas"
                    )}
                    <Modal
                        modal={modalAgregar}
                        idProveedor={
                            proveedor != null
                                ? proveedor[0]?.id
                                : proveedores?.Proveedores[0].id
                        }
                        setModalAgregar={setModalAgregar}
                    />
                </Card.Body>
            </Card>
        </div>
        </div>
    );
}
export default Abm;
