import React, {useState} from "react";
import TextField from '@mui/material/TextField';

export default function Imput(props) {
  const {name, change, type="number", id, value }=props;

  const handleImput = (e) =>{
     change(e,id)
  }
  
  return (
    <div>
      <TextField
        sx={{ m: 1, width: "8ch" }} 
          id="outlined-number"
          onChange={handleImput}
          value={value}
          name={name}
          type={type}
          InputLabelProps={{
            shrink: true,
          }}
        />
    </div>
  );
}
