import apiInstance from "./api";

export function getUpdateProducto  (data){
    return  apiInstance.post('/api/updateProducto', data)
  
    .then(res => { 
      return res.data;
      
    }).catch(error => {
      console.error(error.response);
      throw error.response
    });
  }
  
  

 export function getEliminarProducto (data){
    return  apiInstance.post('/api/deleteProducto', data)
  
    .then(res => { 
      return res.data;
      
    }).catch(error => {
      console.error(error.response);
      throw error.response
    });
  }

  export function getAgregarProducto (data){
    return  apiInstance.post('/api/saveProducto', data)
  
    .then(res => { 
      return res.data;
      
    }).catch(error => {
      console.error(error.response);
      throw error.response
    });
  }
  
