import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Col, Card } from "react-bootstrap";
import { Form, Button } from "react-bootstrap";
import { buscarProductoF } from "../../actions/buscadorProducto";
import Paginator from "react-hooks-paginator";
//import ModalAgregarCliente from './ModalAgregarCliente'
import TablaPedido from "./TablaPedido";
import { Input } from 'rsuite';
import "./pedidos.css";
import { Prev } from "react-bootstrap/esm/PageItem";

import {PDFDownloadLink} from '@react-pdf/renderer'
import PDF from '../../Components/common/PdfPedidos'

export default function Pedidos() {
    const data = useSelector((state) => state.reducerBusqueda);
    const pedidos = useSelector((state)=> state.pedido.pedidos?.data);
    const pedidosRegistros = useSelector((state)=> state.pedido)
    const checks = useSelector((state) => state.pedidoCheck);
    const date = new Date();
    const dateF  = date.getFullYear() + "-" + ("0"+(date.getMonth()+1)).slice(-2) + "-" + ("0" + date.getDate()).slice(-2)

    const dispatch = useDispatch();
    const [proveedor, setProveedor] = useState(null);
    const [modalAgregar, setModalAgregar] = useState(null);
    const [busqueda, setBusqueda] = useState({
        busquedaDato: "",
    });
    console.log(checks)
    const [stateProductos, setStateProductos] = useState([]);
    const [boton, setBoton] = useState(false);
    const [offset, setOffset] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [filterDate ,setFilterDate] = useState(""); 

    let listProveedores = [];

    const imputDatos = (e) => {
        setBusqueda({
            ...busqueda,
            [e.target.name]: e.target.value,
        });
    };

    const enviarDatos = (e) => {
        setBoton(!boton);
    };

    const handleFecha = (event) =>{
        setFilterDate(event)
        console.log(event)
    }

    useEffect(() => {
        const datos = {
            page: currentPage,
            fecha: "",
            busqueda: busqueda.busquedaDato,
        };
        dispatch(buscarProductoF(datos));
        //setFilterDate(dateF)
    }, []);

    useEffect(() => {
        if (data.busqueda_filtro != null) {
            const datos = {
                page: currentPage,
                busqueda: busqueda.busquedaDato,
                fecha: filterDate
            };
            dispatch(buscarProductoF(datos));
        }
    }, [currentPage]);

    useEffect(() => {
        if (boton != false) {
            const datos = {
                page: currentPage,
                busqueda: busqueda.busquedaDato,
                fecha:filterDate
            };
            if (busqueda.busquedaDato.length > 0 || boton != false) {
                dispatch(buscarProductoF(datos));
                setBoton(false);
            }
        }
    }, [boton, busqueda]);

    return (
        <div className="content">
            <div className="body">
            <Card>
                <Card.Header >
                    <Card.Title as="h5">Pedidos</Card.Title>
                    <div className="header">
                    <Col xs={2}>
                        <Form.Control
                            onChange={imputDatos}
                            value={busqueda.busquedaDato}
                            name="busquedaDato"
                            type="text"
                            placeholder=" Ingrese Producto"
                        />
                    </Col>
                    <Col xs={1}>
                        <Input type="date" style={{marginLeft: "5px"}} value={filterDate} onChange={handleFecha}/>
                    </Col>
                    <Form.Group>
                        <Col>
                            <Button  onClick={enviarDatos}>Buscar</Button>
                        </Col>
                    </Form.Group>
                    </div>
                    <Form.Group>
                        <Col style={{ display: "flex" }}>
                            {/* <h5 style={{display: "flex", alignItems: "center" }}>Cliente :</h5> */}
                            {listProveedores}
                        </Col>
                    </Form.Group>
                </Card.Header>
                <Card.Body>
                    {data.busqueda_filtro != null || stateProductos != null ? (
                        <div>
                            <TablaPedido
                                clientes={pedidos != null ? pedidos : null}
                                productos={stateProductos}
                                pagina={currentPage}
                                filter={busqueda.busquedaDato}
                            ></TablaPedido>
                        </div>
                    ) : (
                        "Ingrese busqueda"
                    )}

                    {pedidosRegistros.pedidos?.totalRegister != null ? (
                        <div>
                            <Paginator
                                totalRecords={pedidosRegistros.pedidos.totalRegister}
                                pageLimit={10}
                                pageNeighbours={1}
                                setOffset={setOffset}
                                currentPage={currentPage}
                                setCurrentPage={setCurrentPage}
                            />
                        </div>
                    ) : (
                        "no hay paginas"
                    )}
                    {/* <ModalAgregarCliente modal={modalAgregar} setModalAgregar={setModalAgregar}/> */}
                </Card.Body>
            </Card>
            <>  { checks.pedidos.some((value) => value.stado == true) &&
                                    <div style={{padding: '5px'}}>
                                    <PDFDownloadLink document={<PDF checks={ checks  } />} fileName="archivo.pdf">
                                     {({ blob, url, loading, error }) => (
                                            loading ? (
                                                <Button variant="danger" >descargando Pdf</Button>
                                            ) : (
                                                <Button variant="danger" >descargar PDF</Button>
                                            )
                                        )}
                                    </PDFDownloadLink>
                                    </div>
                                    }
                                    </>
        </div>
        </div>
    );
}
