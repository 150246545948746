import React, { useEffect, useState } from 'react';
import { useDispatch } from "react-redux";
import { Card, Table , Button} from 'react-bootstrap';
import Modal from './ModalEditarProducto';
import {eliminarProducto} from "../../actions/producto";


const TablaAbm = (props) => {
    const [producto, setProducto] = useState(null);

    const dispatch = useDispatch();
  
    const filtro = props.filter;
    const { pagina = null}= props;
    
    const onOff = (e) => {   
        const data =  {
            codigo: e
        }
        dispatch(eliminarProducto(data))
    }
    


    const listItems = props.productos?.map((producto,index) => <tr key={producto.id}><th  scope="row">{index+1}</th>
                                                          <td >{producto.nombre}</td>                         
                                                          <td >{producto.stock}</td>
                                                          <td >${producto.precio}</td>
                                                          <td > <Button variant="success" type="button"  onClick={() => setProducto({... producto})}>Editar</Button> </td>
                                                          <td > <Button variant="danger"   onClick={() => { onOff(producto.id) } } >Eliminar</Button></td></tr> );


        return (
            <div className="table">
            <Card>
            <Card.Header>
                <Card.Title as="h5">Productos</Card.Title>
                <span className="d-block m-t-5"></span>
            </Card.Header>
            <Card.Body>
                <Table responsive hover>
                    <thead>
                    <tr>
                        <th>#</th>
                        <th>Nombre</th>
                        <th>Stock</th>
                        <th>Precio</th>
                        <th>Editar</th>
                        <th>Eliminar</th>

                    </tr>
                    </thead>
                    <tbody>

                        {listItems}
                 
                    </tbody>
                </Table>
                <Modal proveedor={props.proveedor} productos= {producto} pagina={pagina} filter={filtro}/>
            </Card.Body>
        </Card>
        </div>
        );

}
export default TablaAbm;