import { actionTypes } from '../constants/ActionTypes'


export const buscarProductoF = (data) => {
    return {
        type: actionTypes.BUSCAR_PRODUCTO_F,
        data
    }
}

export const result_busqueda = (data) => {
    return {
        type: actionTypes.RESULT_BUSQUEDA,
        data
    }
}

export const modificarRestriccion =(data)=> {

    return {
        type:actionTypes.MODIFICAR_RESTRICCION,
        data
    }
}

export const limpiarBusqueda =(data)=> {

    return {
        type:actionTypes.LIMPIAR_BUSQUEDA,
        data
    }
}


