import React, { useEffect, useState } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import Home from "../../Pages/Home/Home";
import Contacto from "../../Pages/Contacto/Contacto";
import Fag from "../../Pages/Fag/Fag";
import Pedido from "../../Pages/Pedido/Pedido";
import Login from "../../Pages/Login/Login";
import Abm from "../../Pages/ABM/ABM";
import ClienteABM from "../../Pages/ClienteABM/ClienteABM";
import Pedidos from "../../Pages/Pedidos/Pedidos";
import Proveedores from "../../Pages/Proveedores/Proveedor"
import Marca from '../../Pages/Marca/Marca'

import Navbar from "../Nav/Navbar";
import Footer from "../Footer/Footer";

import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import { useDispatch, useSelector } from "react-redux";
import { clearNotificacion } from "../../actions/notificacion";


export default function Rutas() {

    const error = useSelector(state => state.reducerNotificacion)
    
    const [notification, setNotification] = useState(null);

    const dispatch = useDispatch();
    
    useEffect(() => {
        if (error) {
            // Muestra la notificación
            const newNotification = ({
                tipo: error.tipo,
                description: error.descripcion
            });
    
            // Guarda la notificación en el estado
            setNotification(newNotification);
    
            // Limpia la notificación después de cierto tiempo
            setTimeout(() => {
                
                    setNotification(null); // Elimina la notificación del estado
                    dispatch(clearNotificacion());
                    
              
            }, 5000); // Cambia 5000 por el tiempo que desees que la notificación permanezca visible
        }
      }, [error]);
  
    return (
        <>
            <BrowserRouter>
                <Navbar />
                {error   && (
                    <Alert sx={{ width: 300, float:"right"}} severity={error.tipo}>{error.descripcion}</Alert>
                )}
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/contacto" element={<Contacto />} />
                    <Route path="/fag" element={<Fag />} />
                    <Route path="/pedido" element={<Pedido />} />
                    <Route path="/login" element={<Login />} />
                    <Route path="/abm" element={<Abm />} />
                    <Route path="/clientes" element={<ClienteABM />} />
                    <Route path="/marcas" element={<Marca />} />
                    <Route path="/proveedores" element={<Proveedores />} />
                    <Route path="/pedidos" element={<Pedidos />} />
                </Routes>
                <Footer />
            </BrowserRouter>
        </>
    );
}
