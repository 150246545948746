import { actionTypes } from "../constants/ActionTypes";

import {store} from '../store/index'

export default (state = store.login, action) => {
    switch (action.type) {
        case actionTypes.SET_LOGIN:
        //const respuesta = JSON.stringify(action.data.token);
        localStorage.setItem('token',action.data.token);

        return Object.assign({}, state, {
            user_data:  action.data.token != null,
        }) 
        default:
            return state;
    }
}