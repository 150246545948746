import { actionTypes } from "../constants/ActionTypes";

import {store} from '../store/index'

export default (state = store.marcas, action) => {
    switch (action.type) {
        case actionTypes.SET_MARCAS:
        return Object.assign({}, state, {
            marcas:  action.data,
        }) 

        default:
            return state;
        }
}