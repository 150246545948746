import { actionTypes } from '../constants/ActionTypes'

export const modificarProducto = (data) => {

    return {
        type: actionTypes.MODIFICAR_PRODUCTO,
        data
    }
}


export const eliminarProducto = (data) => {

    return {
        type: actionTypes.ELIMINAR_PRODUCTO,
        data
    }
}