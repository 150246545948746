import apiInstance from "./api";

export const getProveedores = (user) => {
    return  apiInstance.post('/api/proveedores', user)
  
    .then(res => { 
      return res.data;
      
    }).catch(error => {
      console.error(error.response);
      throw error.response
    });
}

export const getAddProveedor = (data ) =>{
  return  apiInstance.post('/api/addProveedor', data)
  
  .then(res => { 
    return res.data;
    
  }).catch(error => {
    console.error(error.response);
    throw error.response
  });
}
  
export const getUpdateProveedores  = (data) =>{
  return  apiInstance.post('/api/updateProveedor', data)
  
  .then(res => { 
    return res.data;
    
  }).catch(error => {
    console.error(error.response);
    throw error.response
  });
}