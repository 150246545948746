import { result_busqueda} from '../actions/buscadorProducto'
import { setPedidos } from '../actions/pedido';
import { pedidos } from '../api/pedido'
import { actionTypes } from "../constants/ActionTypes";
import { takeEvery, take, put, call, fork, all } from 'redux-saga/effects'

export const watchBuscarPedido = function * () {
    yield takeEvery(actionTypes.BUSCAR_PRODUCTO_F,fetchBuscar)
}


/*** */
function* fetchBuscar (action) {
    try{
        const data = yield call(pedidos,action.data)
        if (data) {
            yield put(result_busqueda(data))
            yield put(setPedidos(data))
            console.log(data)
        }
    }catch  (err) {
        //yield put(showError())
        console.log(err)
    }


}