import { actionTypes } from "../constants/ActionTypes";
import { takeEvery, call, put } from 'redux-saga/effects';
import loginService from "../api/login";
import { setLogin } from "../actions/login";
import { setNotificacion } from "../actions/notificacion";
//import { verificarLogin } from "../actions/login";

export const watchLoginService = function * (){
    yield takeEvery(actionTypes.FETCH_GET_LOGIN, fetchLoginUser)
}

function* fetchLoginUser(action){
    try{
        const data = yield call(loginService, action.data)
        if(data){
            yield put(setLogin(data))
            // yield put(verificarLogin())

            // toast.success('¡Bienvenido a Urus!', {
            // });
        }
    }catch(err){
        console.log(err);
        const data={
            tipo:"error",
            descripcion:"¡Revise los campos!"
        }
        yield put(setNotificacion(data))
        // toast.error('¡Revise los campos!', {
        // });
    }
}