import React, { useState,useEffect } from 'react';
import { Modal, Button } from 'rsuite';

import { useSelector, useDispatch } from 'react-redux';

import {agregarProducto} from "../../actions/tablaPedido"


function Editar(props) {
    const {modal, idProveedor, setModalAgregar}=props
    const dispatch = useDispatch();
    const [imput,setImput]=useState({
        codigo:"",
        nombre:"",
        descripcion:"",
        stock:"",
        precio:""
    });

    const restric = useSelector((state) => state.reducerBusqueda );
    
    const [show, setShow] = useState(modal != null);

    useEffect(() => {
      setShow(modal != null)
    }, [modal])

    const valorImput = (e) =>{
          setImput({
            ...imput,
            [e.target.name]: e.target.value})       
    }
  
    const handleClose = () => {
            console.log(idProveedor)
            if(imput.descripcion=="" || imput.nombre=="" || imput.stock =="" || imput.precio=="" || imput.precio<1){
              
            }else{
              const dato = {
                 nombre : imput.nombre,
                 stock: Number.parseInt(imput.stock),
                 descripcion: imput.descripcion,
                 idProveedor : Number.parseInt(idProveedor),
                 precio: Number.parseInt(imput.precio)
              }
              console.log(dato)
              dispatch(agregarProducto(dato))
              setModalAgregar(null)
              setShow(false);
              setImput({
                codigo:"",
                nombre:"",
                descripcion:"",
                stock:"",
                precio:""
            })
            ;}
    };
    const handleCloseButton=()=>{
      setModalAgregar(null)
      setShow(false);
      setImput({
        codigo:"",
        nombre:"",
        descripcion:"",
        stock:"",
        precio:""
    })
    } 
       
    const handleShow = () => setShow(true);

    return (
      <>
         {(show) &&
  
        <Modal open={show} onClose={handleCloseButton}>
          <Modal.Header >
            <Modal.Title>+Agregar Producto</Modal.Title>
          </Modal.Header>
          <Modal.Body>
              <h3>Codigo:</h3>
              <div className="form-group">
              <input type="number" className="form-control input-sm" onChange={valorImput} value={imput.codigo} name="codigo" min={1}   required ></input>
              </div> 
              <h3>Nombre:</h3>
              <div className="form-group">
              <input type="text" className="form-control input-sm" onChange={valorImput} value={imput.nombre} name="nombre" min={1}   required ></input>
              </div> 
              <h3>Descripcion:</h3>
              <div className="form-group">
              <input type="text" className="form-control input-sm" onChange={valorImput} value={imput.descripcion} name="descripcion" min={1}   required ></input>
              </div> 
              <h3>Stock:</h3>
              <div className="form-group">
              <input type="number" className="form-control input-sm" onChange={valorImput} value={imput.stock} name="stock" min={1}   required ></input>
              </div>
              <h3>Precio:</h3>
              <div className="form-group">
              <input type="number" className="form-control input-sm" onChange={valorImput} value={imput.precio} name="precio" min={1}   required ></input>
              </div>                                    
          </Modal.Body>
          <Modal.Footer>
            <Button appearance="primary" color="red" onClick={handleCloseButton}>
              Cerrar
            </Button>
            <Button  appearance="primary" type="submit" onClick={handleClose}>
              Agregar
            </Button>
          </Modal.Footer>
        </Modal>
         }
         
      </>
    );
  }
  
  export default Editar;