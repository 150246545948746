import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./login.css";
import { getLogin } from "../../actions/login";
import { Button } from "rsuite";


export default function Login() {
  const [buttonLoading, setButtonLoading]= useState(false);
  const [estado, setEstado] = useState({
    username: "",
    password: "",
  });

  const userLoginToken = localStorage.getItem("token");
  const shouldRedirect = useSelector(state => state.login);
  const error = useSelector(state => state.reducerNotificacion)

  const dispatch = useDispatch();

  useEffect(() => {
    if (userLoginToken != null) {
      window.location.href = '/'; // Realiza la redirección manualmente
    }
}, [userLoginToken]);

  const handleChange = (event) => {
    setEstado({
      ...estado,
      [event.target.name]: event.target.value,
    });
  };

  const handleSbmit = () => {
    //enviar a bd
    setButtonLoading(true);
    const data = {
      username: estado.username,
      password: estado.password,
    };
    dispatch(getLogin(data));
  };

  const pressEnter = (e) => {
    if (e.key === 'Enter') {
      handleSbmit();
    }
  }

  useEffect(() => {
    if (error && error.descripcion == "¡Revise los campos!") {
      setButtonLoading(false);
    }
}, [error]);


  return (
    <div className="login">
      <div className="bodyLogin">
        <h1>Login</h1>
        {/* <form onSubmit={handleSbmit}> */}
        <div className="formInput">
          <label className="labelImput">Email:</label>
          <input
            name="username"
            value={estado.username}
            type="text"
            onChange={handleChange}
            onKeyPress={(e) => pressEnter(e)}
          />
        </div>
        <div className="formInput">
          <label className="labelImput">Contraseña:</label>
          <input
            name="password"
            value={estado.password}
            type="password"
            onChange={handleChange}
            onKeyPress={(e) => pressEnter(e)}
          />
        </div>
        
          <Button loading={buttonLoading} className="itemButton" type="submit" onClick={handleSbmit}>
            Enviar
          </Button>
        
        {/* </form> */}
      </div>
    </div>
  );
}
