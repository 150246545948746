import { all } from'redux-saga/effects';
import { watchLoginService} from './watchLogin'
import { watchProveedores } from './watchProveedores';
import { watchPedido } from './watchPedidoi';
import { watchProducto } from './watchProducto';
import {watchCliente} from './watchCliente'
import {watchBuscarPedido} from './busqueda'
import { watchMarcas } from './wacthMarcas';

export default function * rootSaga(){
    yield all ([
        watchLoginService(),
        watchProveedores(),
        watchPedido(),
        watchProducto(),
        watchCliente(),
        watchBuscarPedido(),
        watchMarcas(),
    ])
 
}