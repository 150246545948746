import { actionTypes } from "../constants/ActionTypes";

import {store} from '../store/index'

export default (state = store.pedidos, action) => {
    switch (action.type) {
        case actionTypes.FETCH_SET_PEDIDOS:
        return Object.assign({}, state, {
            pedidos:  action.data,
        }) 

        default:
            return state;
        }
}