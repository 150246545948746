import axios from "axios";

const BASE_URL = "https://pedidosbc.com/api/public/";
// const BASE_URL = "http://127.0.0.1:8000";
// const BASE_URL = "http://127.0.0.1:8001";


const apiInstance = axios.create({ baseURL: BASE_URL })
var userLoginToken = localStorage.getItem("token");

apiInstance.interceptors.request.use(
  (request) => {
    request.headers = {
      Authorization: `Bearer ` + localStorage.getItem('token')
    }
    return request;
  }
)

apiInstance.interceptors.response.use(
  (response) => response,
  (err) => {
    if (err.response.data.message === "Expired JWT Token") {
      localStorage.removeItem("token");
      localStorage.clear();
      window.location = '/login'
      // toast.success('Hasta pronto!', {
      // });
    }

    // if(userLoginToken != null && err.response.data.message === "Access Denied"){
    //     window.location = '/sin-acceso';
    // }

    if (err.response.data.message === "Invalid credentials.") {
    //   toast.error('Revise su mail y/o contraseña', {
    //   });
    console.log(err.response.data.message)
    }
    return Promise.reject(err.response.data);
  }
);

export default apiInstance;