import { actionTypes } from "../constants/ActionTypes";

import {store} from '../store/index'

export default (state = store.proveedores, action) => {
    switch (action.type) {
        case actionTypes.SET_PROVEEDORES:
        return Object.assign({}, state, {
            proveedores:  action.data,
        }) 

        default:
            return state;
        }
}