import { actionTypes } from "../constants/ActionTypes";

export function getClientes(data) {
    return {
        type: actionTypes.FETCH_GET_CLIENTES,
        data,
    };
}

export function setClientes(data){
    return{
        type: actionTypes.SET_CLIENTES,
        data,
    }
}

export function agregarCliente(data) {
    return {
        type: actionTypes.ADD_CLIENTE,
        data,
    };
}

export const modificarCliente = (data) => {

    return {
        type: actionTypes.MODIFICAR_CLIENTE,
        data
    }
}