import { actionTypes } from "../constants/ActionTypes";

export function getLogin(data) {
    return {
        type: actionTypes.FETCH_GET_LOGIN,
        data,
    };
}

export function setLogin(data){
    return{
        type: actionTypes.SET_LOGIN,
        data,
    }
}
