import React, { useState,useEffect } from 'react';
import { Modal, Button } from 'rsuite';

import { useSelector, useDispatch } from 'react-redux';
import { agregarProveedor } from '../../actions/proveedores';


function ModalAgregarProveedor(props) {
    const {modal, idProveedor, setModalAgregar}=props
    const dispatch = useDispatch();
    const [imput,setImput]=useState({
        codigo:"",
        telefono:"",
    });

    const restric = useSelector((state) => state.reducerBusqueda );
    
    const [show, setShow] = useState(modal != null);

    useEffect(() => {
      setShow(modal != null)
    }, [modal])

    const valorImput = (e) =>{
          setImput({
            ...imput,
            [e.target.name]: e.target.value})       
    }
  
    const handleClose = () => {
            console.log(idProveedor)
            if( imput.nombre=="" || imput.telefono =="" ){
              
            }else{
              const dato = {
                 nombre : imput.nombre,
                 telefono: Number.parseInt(imput.telefono),
              }
              console.log(dato)
              dispatch(agregarProveedor(dato))
              setModalAgregar(null)
              setShow(false);
              setImput({
                nombre:"",
                telefono:"",
            })
            ;}
    };
    const handleCloseButton=()=>{
      setModalAgregar(null)
      setShow(false);
      setImput({
        nombre:"",
        telefono:"",   
    })
    } 
       
    const handleShow = () => setShow(true);

    return (
      <>
         {(show) &&
  
        <Modal open={show} onClose={handleCloseButton}>
          <Modal.Header >
            <Modal.Title>+Agregar Proveedor</Modal.Title>
          </Modal.Header>
          <Modal.Body> 
              <h3>Nombre:</h3>
              <div className="form-group">
              <input type="text" className="form-control input-sm" onChange={valorImput} value={imput.nombre} name="nombre" min={1}   required ></input>
              </div>  
              <h3>Telefono:</h3>
              <div className="form-group">
              <input type="number" className="form-control input-sm" onChange={valorImput} value={imput.telefono} name="telefono" min={1}   required ></input>
              </div>                               
          </Modal.Body>
          <Modal.Footer>
            <Button appearance="primary" color="red" onClick={handleCloseButton}>
              Cerrar
            </Button>
            <Button  appearance="primary" type="submit" onClick={handleClose}>
              Agregar
            </Button>
          </Modal.Footer>
        </Modal>
         }
         
      </>
    );
  }
  
  export default ModalAgregarProveedor;