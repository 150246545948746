import React, { useState,useEffect } from 'react';
import { Modal, Button } from 'rsuite';
import { useSelector, useDispatch } from 'react-redux';
import {buscarProductoF} from "../../actions/buscadorProducto";

import {modificarProducto} from "../../actions/producto"
import { modificarMarca } from '../../actions/marca';



const EditarMarca=(props)=> {
    const productos = props.productos
    const idMarca = props.proveedor
    
    if(productos != null){
      
      var obj = JSON.stringify(productos)
      var dataProps = JSON.parse(obj)
     
      var nombre = dataProps.nombre
      var telefono = dataProps.telefono
      var id = dataProps.id
    
      // var descripcion = dataProps.descripcion
      // var codigo = dataProps.codigo
     
    }
    const dispatch = useDispatch();
    const [imput,setImput]=useState({
        nombre:nombre,
        telefono:telefono,
        id:id,
        // descripcion:descripcion,
        // codigo:codigo
        
    });

    const restric = useSelector(state => state.reducerBusqueda );
    
    const [show, setShow] = useState(productos != null);

    useEffect(() => {
      setShow(productos != null)
      setImput({
        nombre:nombre,
        id:id,        
      });
      
    }, [productos])

    const valorImput = (e) =>{
      
      
          setImput({
            ...imput,
            [e.target.name]: e.target.value})
          
    }
  
    const handleClose = (e) => {
    
     const item = {
        _id :dataProps.id,
        nombre:imput.nombre,
        idMarca: idMarca
      }
       
      
     if (imput.nombre == "" ) {
           
           
          }else{
            dispatch(modificarMarca(item))
        //     let timerId = setTimeout(() => dispatch(buscarProductoF({
        //       pag:props.pagina,
        //       filter:props.filter
        //   })), 3000);            
            setShow(false)
          }
     
     }   
   
    const handleCloseButton=()=>{
      setShow(false);
    }
    
    const handleShow = () => setShow(true);

  
    return (
      <>
      {(show) &&
  
        <Modal open={show} onClose={handleClose}>
          <Modal.Header >
            <Modal.Title>{nombre}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
              <h3>Nombre:</h3>
              <div className="form-group">
              <input type="text" className="form-control input-sm" onChange={valorImput} value={imput.nombre} name="nombre" min={1}  max={restric.busqueda_restric==true ? props.productos.stock : ""} required ></input>
              </div> 
                                             
          </Modal.Body>
          <Modal.Footer>
            <Button appearance="secondary" onClick={handleCloseButton}>
              Cerrar
            </Button>
            <Button  appearance="primary" type="submit" onClick={handleClose}>
              Editar 
            </Button>
          </Modal.Footer>
        </Modal>
      } 
      </>
    );
  }
  
  export default EditarMarca;