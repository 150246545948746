import React, { useState,useEffect } from 'react';
import { Modal, Button } from 'rsuite';
import { useSelector, useDispatch } from 'react-redux';
import {buscarProductoF} from "../../actions/buscadorProducto";

import {modificarProducto} from "../../actions/producto"



const EditarProducto=(props)=> {
    const productos = props.productos
    const idProveedor = props.proveedor
    
    if(productos != null){
      
      var obj = JSON.stringify(productos)
      var dataProps = JSON.parse(obj)
     
      var nombre = dataProps.nombre
      var stock = dataProps.stock
      var precio = dataProps.precio
    
      // var descripcion = dataProps.descripcion
      // var codigo = dataProps.codigo
     
    }
    const dispatch = useDispatch();
    const [imput,setImput]=useState({
        nombre:nombre,
        stock:stock,
        precio:precio,
        // descripcion:descripcion,
        // codigo:codigo
        
    });

    const restric = useSelector(state => state.reducerBusqueda );
    
    const [show, setShow] = useState(productos != null);

    useEffect(() => {
      setShow(productos != null)
      setImput({
        nombre:nombre,
        stock:stock,
        precio:precio,
        // descripcion:descripcion,
        // codigo:codigo
        
      });
      
    }, [productos])

    const valorImput = (e) =>{
      
      
          setImput({
            ...imput,
            [e.target.name]: e.target.value})
          
    }
  
    const handleClose = (e) => {
    
     const item = {
      _id :dataProps.id,
      nombre:imput.nombre,
      stock:Number.parseInt(imput.stock),
      // descripcion:imput.descripcion,
      // codigo:Number.parseInt(imput.codigo),
      precio:Number.parseInt(imput.precio),
      idProveedor: idProveedor
        }
       
      
     if (imput.nombre == ""  || imput.precio == ""  || imput.stock == "") {
           
           
          }else{
            console.log(item.precio, item)
            dispatch(modificarProducto(item))
            let timerId = setTimeout(() => dispatch(buscarProductoF({
              pag:props.pagina,
              filter:props.filter
          })), 3000);
            

            
            setShow(false)
          }
     
     }   
   
    const handleCloseButton=()=>{
      setShow(false);
    }
    
    const handleShow = () => setShow(true);

  
    return (
      <>
      {(show) &&
  
        <Modal open={show} onClose={handleClose}>
          <Modal.Header >
            <Modal.Title>{nombre}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
              <h3>Nombre:</h3>
              <div className="form-group">
              <input type="text" className="form-control input-sm" onChange={valorImput} value={imput.nombre} name="nombre" min={1}  max={restric.busqueda_restric==true ? props.productos.stock : ""} required ></input>
              </div> 
              <h3>Codigo:</h3>
              <div className="form-group">
              <input type="number" className="form-control input-sm" onChange={valorImput} value={imput.codigo} name="codigo" min={1}  max={restric.busqueda_restric==true ? props.productos.stock : ""} required ></input>
              </div> 
              <h3>Stock:</h3>
              <div className="form-group">
              <input type="number" className="form-control input-sm" onChange={valorImput} value={imput.stock} name="stock" min={1}  max={restric.busqueda_restric==true ? props.productos.stock : ""} required ></input>
              </div>
              <h3>Precio:</h3>
              <div className="form-group">
              <input type="number" className="form-control input-sm" onChange={valorImput} value={imput.precio} name="precio" min={1}  max={restric.busqueda_restric==true ? props.productos.stock : ""} required ></input>
              </div>  
                                            
          </Modal.Body>
          <Modal.Footer>
            <Button appearance="secondary" onClick={handleCloseButton}>
              Cerrar
            </Button>
            <Button  appearance="primary" type="submit" onClick={handleClose}>
              Editar 
            </Button>
          </Modal.Footer>
        </Modal>
      } 
      </>
    );
  }
  
  export default EditarProducto;