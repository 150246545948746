import React, { useState, useEffect, cloneElement } from "react";

//import logo from './imagenes/logo.png'
import {
    Page,
    Text,
    View,
    Document,
    StyleSheet,
    Image,
    PDFViewer,
} from "@react-pdf/renderer";

import { useDispatch, useSelector } from "react-redux";

const BORDER_COLOR = "#bfbfbf";
const BORDER_STYLE = "solid";
const COL1_WIDTH = 33;
const COLN_WIDTH = (165 - COL1_WIDTH) / 2;
const styles = StyleSheet.create({
    body: {
        padding: 10,
    },
    table: {
        display: "table",
        width: "auto",
        borderStyle: BORDER_STYLE,
        borderColor: BORDER_COLOR,
        borderWidth: 1,
        borderRightWidth: 0,
        borderBottomWidth: 0,
    },
    tableRow: {
        margin: "auto",
        flexDirection: "row",
    },
    tableCol1Header: {
        width: COL1_WIDTH + "%",
        borderStyle: BORDER_STYLE,
        borderColor: BORDER_COLOR,
        borderBottomColor: "#000",
        borderWidth: 1,
        borderLeftWidth: 0,
        borderTopWidth: 0,
    },
    tableColHeader: {
        width: COLN_WIDTH + "%",
        borderStyle: BORDER_STYLE,
        borderColor: BORDER_COLOR,
        borderBottomColor: "#000",
        borderWidth: 1,
        borderLeftWidth: 0,
        borderTopWidth: 0,
    },
    tableCol1: {
        width: COL1_WIDTH + "%",
        borderStyle: BORDER_STYLE,
        borderColor: BORDER_COLOR,
        borderWidth: 1,
        borderLeftWidth: 0,
        borderTopWidth: 0,
    },
    tableCol: {
        width: COLN_WIDTH + "%",
        borderStyle: BORDER_STYLE,
        borderColor: BORDER_COLOR,
        borderWidth: 1,
        borderLeftWidth: 0,
        borderTopWidth: 0,
    },
    tableCellHeader: {
        margin: 5,
        fontSize: 12,
        fontWeight: 500,
    },
    tableCell: {
        margin: 5,
        fontSize: 10,
        textAlign: "center",
    },
    image: {
        padding: "3px",
        margin: "3px",
        height: 65,
        width: 150,
        margin: 5,
        marginLeft: "1",
        marginLeft: "205",
    },
    header: {
        height: 150,
        width: "100%",
        display: "flex",
        alignItems: "flex-start",
        justifyContent: "space-between",
    },
    fecha: {
        marginLeft: "257",
        fontSize: 13,
    },
    headerInfo: {
        width: "70%",
        float: "letf",
        margin: "2%",
        fontSize: 10,
        flex: 4,
        flexDirection: "row",
        flexWrap: "wrap",
        alignItems: "flex-start",
    },
    headerImg: {
        flexDirection: "row",
        flexWrap: "wrap",

        marginRight: "20px",

        float: "rigth",
        width: 500,

        margin: "1%",
    },

    numeroPre: {
        width: "320px",
        padding: "2px",
        border: "2px solid",
        margin: 0,
        fontSize: 12,
        fontWeight: "bold",
    },
    frase: {
        fontSize: 10,
        textAlign: "left !important",
        padding: "10px",
        fontWeight: "bold",
        width: "30px",
    },
});

let today = new Date(),
    date =
        today.getDate() +
        "-" +
        (today.getMonth() + 1) +
        "-" +
        today.getFullYear();

const PDF = (props) => {
    const { checks } = props;
    const mapeo = {};
    if(checks && checks.pedidos && checks.pedidos.length > 0){
      let chequeados = checks.pedidos.filter((item) => item.stado == true);
        
          chequeados.forEach((item) => {
            if (item.pedido && item.pedido.productos && item.pedido.productos.length > 0) {
                item.pedido.productos.forEach(producto => {
                    if (mapeo[producto.idProducto]) {
                        mapeo[producto.idProducto].cantidad += parseInt(producto.cantidad);
                        mapeo[producto.idProducto].cambio += parseInt(producto.cambio);
                    } else {
                        mapeo[producto.idProducto] = {
                            idProducto: producto.idProducto,
                            cantidad: parseInt(producto.cantidad),
                            cambio: parseInt(producto.cambio),
                            productoNombre: producto.productoNombre
                        };
                    }
                });
            }
          });
    }
    
    const nuevoArray = Object.values(mapeo);
    console.log(nuevoArray);

    return (
        <Document>
            <Page size="A4" style={styles.body}>
                <View style={styles.header}>
                    <View style={styles.headerInfo}>
                        <Text style={styles.headerInfo}>
                            {"\n"}
                            <Text style={styles.headerInfo}>
                                distribuidoraBC@gmail.com
                            </Text>{" "}
                            {"\n"}
                            <Text style={styles.headerInfo}>
                                www.pedidosBC.com.ar
                            </Text>
                            {"\n"}
                            <Text style={styles.headerInfo}>
                                Calle 122 #1006
                            </Text>{" "}
                            {"\n"}
                            <Text style={styles.headerInfo}>
                                La Plata - Buenos Aires
                            </Text>{" "}
                            {"\n"}
                            <Text style={styles.headerInfo}>
                                +54 9 221 - 2213641029 
                            </Text>{" "}
                            {"\n"}
                        </Text>

                        <View style={styles.headerInfo}>
                            {/* <Image
                                                        style={styles.image}
                                                        source={
                                                          logo }
                                                        
                                                      /> */}

                            <Text style={styles.fecha}>
                                {date}
                            </Text>
                        </View>
                    </View>
                </View>

                <View style={styles.table}>
                    <View style={styles.tableRow}>
                        <View style={styles.tableColHeader}>
                            <Text style={styles.tableCellHeader}>
                                Producto
                            </Text>
                        </View>
                        <View style={styles.tableCol1Header}>
                            <Text style={styles.tableCellHeader}>Cantidad</Text>
                        </View>
                    </View>
                    {nuevoArray
                        ? nuevoArray.map((a, index) => {
                              return  (
                                  <View key={index} style={styles.tableRow}>
                                      <View style={styles.tableCol}>
                                          <Text style={styles.tableCell}>
                                             {a.productoNombre}
                                          </Text>
                                      </View>
                                      <View style={styles.tableCol1}>
                                          <Text style={styles.tableCell}>
                                              {a.cantidad + a.cambio}
                                          </Text>
                                      </View>
                                  </View>
                              )})
                          
                        : ""
                      }
                </View>
            </Page>
        </Document>
    );
};
export default PDF;
