import { actionTypes } from "../constants/ActionTypes";
import { takeEvery, call, put, take, select } from 'redux-saga/effects';
import {getAgregarProducto, getUpdateProducto, getEliminarProducto} from "../api/producto";
import { modificarProducto } from "../actions/producto";
import { getProveedores } from "../actions/proveedores";
import { setTablaPedido } from "../actions/tablaPedido";
//import { verificarLogin } from "../actions/login";

export const watchProducto = function * (){
    yield takeEvery(actionTypes.MODIFICAR_PRODUCTO, fetchUpdateProducto)
    yield takeEvery(actionTypes.ELIMINAR_PRODUCTO, fetchDeleteProducto)
    yield takeEvery(actionTypes.AGREGAR_UN_PRODUCTO, fetchAgregarProducto)
}

function* fetchAgregarProducto(action){
    try{
        const data = yield call(getAgregarProducto, action.data);
        if(data){
            yield put({ type: "FETCH_GET_MARCAS" });
            yield take("SET_MARCAS");

            const stateProveedor = yield select(
                (state) => state.marca.marcas
            );
            yield put(setTablaPedido(stateProveedor.Proveedores))
        }
    }catch(err){
        console.log(err);
        // toast.error('¡Revise los campos!', {
        // });
    }
}

function* fetchUpdateProducto(action){
    try{
        const data = yield call(getUpdateProducto, action.data);
        if(data){
            //yield put(modificarProducto(data));
            yield put({ type: "FETCH_GET_MARCAS" });
            yield take("SET_MARCAS");
            //actualizar producto
        }
    }catch(err){
        console.log(err);
        // toast.error('¡Revise los campos!', {
        // });
    }
}

function* fetchDeleteProducto(action){
    try{
        const data = yield call(getEliminarProducto, action.data);
        if(data){
            //yield put(eliminarProducto(data));
            //elinar producto
        }
    }catch(err){
        console.log(err);
        // toast.error('¡Revise los campos!', {
        // });
    }
}