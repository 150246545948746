import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Col, Card } from "react-bootstrap";
import { Form, Button } from "react-bootstrap";
import TablaProveedores from "./TablaProveedores";
import { buscarProductoF } from "../../actions/buscadorProducto";
import Paginator from "react-hooks-paginator";
import ModalAgregarProveedor from "./ModalAgregarProveedor";
import { getProveedores } from "../../actions/proveedores";
import "./proveedores.css";

export default function Proveedor() {
    
    const data = useSelector((state) => state.reducerBusqueda);
    const proveedores = useSelector((state) => state.proveedores.proveedores);

    const dispatch = useDispatch();
    const [proveedor, setProveedor] = useState(null);
    const [modalAgregar, setModalAgregar] = useState(null);
    const [busqueda, setBusqueda] = useState({
        busquedaDato: "",
    });

    const [stateProductos, setStateProductos] = useState([]);
    const [boton, setBoton] = useState(false);
    const [offset, setOffset] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);

    let listProveedores = [];

    const handleProveedor = (event) => {
        let positionArray = proveedores.Proveedores?.filter(
            (item) => item.id == event.target.value
        );
        setProveedor(positionArray);
        if (positionArray[0].productos !== null) {
            setStateProductos(positionArray[0].productos);
        } else {
            setStateProductos(null);
        }
    };

    proveedores?.Proveedores?.forEach((element, index) => {
        listProveedores.push(
            
                element
            
        );
    });

    const imputDatos = (e) => {
        setBusqueda({
            ...busqueda,
            [e.target.name]: e.target.value,
        });
    };

    const enviarDatos = (e) => {
        setBoton(!boton);
    };

    useEffect(() => {
        const datos = {
            pag: currentPage,
            filter: busqueda.busquedaDato,
        };
        dispatch(buscarProductoF(datos));
        if(proveedores==null){
            dispatch(getProveedores());
        }
        setStateProductos(proveedores?.Proveedores[0].productos);
    }, []);

    useEffect(() => {
        if (data.busqueda_filtro != null) {
            const datos = {
                pag: currentPage,
                filter: busqueda.busquedaDato,
            };
            dispatch(buscarProductoF(datos));
        }
    }, [currentPage]);

    useEffect(() => {
        if (boton != false) {
            const datos = {
                pag: currentPage,
                filter: busqueda.busquedaDato,
            };
            if (busqueda.busquedaDato.length > 0) {
                dispatch(buscarProductoF(datos));
                setBoton(false);
            }
        }
    }, [boton, busqueda]);

    useEffect(() => {
     console.log("cambio");
     setStateProductos(proveedores?.Proveedores)
  }, [proveedores]);

    return (
        <div className="content">
            <div className="body">
            <Card>
                <Card.Header>
                    <Card.Title as="h5">ABM Proveedores</Card.Title>
                    <div className="celular-pro">
                    <Col xs={7}>
                        <Form.Control
                            onChange={imputDatos}
                            value={busqueda.busquedaDato}
                            name="busquedaDato"
                            type="text"
                            placeholder=" Ingrese Producto"
                        />
                    </Col>
                    <Form.Group>
                        <Col>
                            <Button onClick={enviarDatos}>Buscar</Button>
                            <Button
                                variant="primary"
                                type="button"
                                onClick={() => setModalAgregar(true)}
                            >
                                +
                            </Button>
                        </Col>
                    </Form.Group>
                    </div>
                </Card.Header>
                <Card.Body>
                    {data.busqueda_filtro != null || stateProductos != null ? (
                        <div>
                            <div>
                                <TablaProveedores
                                    proveedor={
                                        proveedor != null ? proveedor[0].id : 1
                                    }
                                    productos={listProveedores}
                                    pagina={currentPage}
                                    filter={busqueda.busquedaDato}
                                ></TablaProveedores>
                            </div>
                        </div>
                    ) : (
                        "Ingrese busqueda"
                    )}

                    {/* {<pre>{JSON.stringify(data1.productos,null,2)}</pre>} */}
                    {data.busqueda_filtro != null ? (
                        <div>
                            <Paginator
                                totalRecords={data.busqueda_filtro.total}
                                pageLimit={3}
                                pageNeighbours={1}
                                setOffset={setOffset}
                                currentPage={currentPage}
                                setCurrentPage={setCurrentPage}
                            />
                        </div>
                    ) : (
                        "no hay paginas"
                    )}
                    <ModalAgregarProveedor
                        modal={modalAgregar}
                        idProveedor={
                            proveedor != null
                                ? proveedor[0]?.id
                                : proveedores?.Proveedores[0]?.id
                        }
                        setModalAgregar={setModalAgregar}
                    />
                </Card.Body>
            </Card>
        </div>
        </div>
    );
}
