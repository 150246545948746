import { actionTypes } from "../constants/ActionTypes";
import { takeEvery, call, put, take } from 'redux-saga/effects';
import {getProveedores, getAddProveedor , getUpdateProveedores}from "../api/proveedores";

import { setProveedores } from "../actions/proveedores";
//import { verificarLogin } from "../actions/login";

export const watchProveedores = function * (){
    yield takeEvery(actionTypes.FETCH_GET_PROVEEDORES, fetchProveedores)
    yield takeEvery(actionTypes.ADD_PROVEEDORES, fetchAddProveedor)
    yield takeEvery(actionTypes.UPDATE_PROVEEDORES, fetchUpdateProveedor )
}

function* fetchProveedores(action){
    try{
        const data = yield call(getProveedores, action.data);
        if(data){
            yield put(setProveedores(data));
        }
    }catch(err){
        console.log(err);
        // toast.error('¡Revise los campos!', {
        // });
    }
}

function* fetchAddProveedor(action){
    try{
        const data = yield call(getAddProveedor, action.data);
        if(data){
            yield put({ type: "FETCH_GET_PROVEEDORES" });
            yield take("SET_PROVEEDORES");
        }
    }catch(err){
        console.log(err);
        // toast.error('¡Revise los campos!', {
        // });
    }
}

function* fetchUpdateProveedor(action){
    try{
        const data = yield call(getUpdateProveedores, action.data);
        if(data){
            yield put({ type: "FETCH_GET_PROVEEDORES" });
            yield take("SET_PROVEEDORES");
        }
    }catch(err){
        console.log(err);
        // toast.error('¡Revise los campos!', {
        // });
    }
}