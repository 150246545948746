import { actionTypes } from "../constants/ActionTypes";

import {store} from '../store/index'

export default (state = store.cliente, action) => {
    switch (action.type) {
        case actionTypes.SET_CLIENTES:
            let xCliente = action.data
        return Object.assign({}, state, {
            cliente:  xCliente,
        }) 

        default:
            return state;
        }
}