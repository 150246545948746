import { actionTypes } from "../constants/ActionTypes";

export function getEnviarPedido(data) {
    return {
        type: actionTypes.FETCH_GET_ENVIAR_PEDIDO,
        data,
    };
}

export function getPedidos(data) {
    return {
        type: actionTypes.FETCH_PEDIDOS,
        data,
    };
}

export function setPedidos(data) {
    return {
        type: actionTypes.FETCH_SET_PEDIDOS,
        data,
    };
}
