import React, { useEffect, useState } from 'react';
import { useDispatch } from "react-redux";
import { Card, Table, Button} from 'react-bootstrap';
import Modal from './ModalEditarCliente';
import {eliminarProducto} from "../../actions/producto";
import { AiOutlineClose, AiOutlineEdit } from "react-icons/ai"

const TablaAbm = (props) => {
    const [producto, setProducto] = useState(null);

    const dispatch = useDispatch();
  
    const filtro = props.filter;
    const { pagina = null}= props;
    
    const onOff = (e) => {   
        const data =  {
            codigo: e
        }
        dispatch(eliminarProducto(data))
    }
    


    const listItems = props.clientes?.map((producto,index) => <tr key={producto.id}><th  scope="row">{index+1}</th>
                                                          <td >{producto.nombre}</td>                         
                                                          <td >{producto.telefono}</td>
                                                          <td >{producto.direccion}</td>
                                                          <td > <Button variant="black" type="button"  style={{fontSize: "23px"}} onClick={() => setProducto({... producto})}><AiOutlineEdit></AiOutlineEdit></Button> </td>
                                                          <td > <Button variant="black"  style={{fontSize: "23px"}} onClick={() => { onOff(producto.id) } } ><AiOutlineClose></AiOutlineClose></Button></td></tr> );


        return (
            <div className="table">
            <Card>
            <Card.Header>
                <Card.Title as="h5">Clientes</Card.Title>
                <span className="d-block m-t-5"></span>
            </Card.Header>
            <Card.Body>
                <Table responsive hover>
                    <thead>
                    <tr>
                        <th>#</th>
                        <th>Nombre</th>
                        <th>Telefono</th>
                        <th>Direccion</th>
                        <th>Editar</th>
                        <th>Eliminar</th>

                    </tr>
                    </thead>
                    <tbody>

                        {listItems}
                 
                    </tbody>
                </Table>
                <Modal proveedor={props.proveedor} productos= {producto} pagina={pagina} filter={filtro}/>
            </Card.Body>
        </Card>
        </div>
        );

}
export default TablaAbm;