import { actionTypes } from "../constants/ActionTypes";

import {store} from '../store/index'

export default (state = store.tablaPedido, action) => {
    switch (action.type) {
        case actionTypes.SET_TABLA_PEDIDO:
            let tabla = {};
            const proveeedores = action.data;
            proveeedores.forEach((item,index) => { 
                let productos = {};
                item.productos?.forEach((element, index) => {
                    const data = {
                        id: element.id, 
                        nombreProducto: element.nombre,
                        cantidad:0, 
                        cambio:0,
                       precio:element.precio
                    }
                    productos[element.id]=data   
                })
                tabla[item.id]={ "idProveedor":item.id, "proveedorNombre":item.nombre, "pro": productos }
            })
            
    
        return Object.assign({}, state, {
            tablaPedido:  tabla,
        }) 

        case actionTypes.UPDATE_TABLA_PEDIDO:
            return {
                ...state,
                tablaPedido:{               
                    ...state.tablaPedido,
                    [action.data.idProveedor]:{
                        ...state.tablaPedido[action.data.idProveedor],
                        pro:{
                            ...state.tablaPedido[action.data.idProveedor].pro,
                            [action.data.id]:{
                                ...state.tablaPedido[action.data.idProveedor].pro[action.data.id],
                                ...action.data
                            }
                        }
                    }
                }
            }

        default:
            return state;
        }
}