import "./App.css";
import { Provider } from "react-redux";
import rootReducer from "./reducer";
import rootSaga from "./sagas";
import createSagaMiddleware from "@redux-saga/core";
import { applyMiddleware, createStore } from "redux";
import Rutas from "./Components/Rutas/Rutas";
import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "rsuite/dist/rsuite.min.css";

function App() {
    const sagasMiddleware = createSagaMiddleware();
    const store = createStore(rootReducer, applyMiddleware(sagasMiddleware));
    sagasMiddleware.run(rootSaga);

    return (
        <Provider store={store}>
            <div style={{ background: "#000" }}>
                <Rutas />
            </div>
        </Provider>
    );
}

export default App;
