import React, { useState } from 'react';
import './contacto.css'
import { useForm } from "react-hook-form";

import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';


export default function Contacto() {

  const {
    register,
    reset,
    control,
    formState: { errors },
    getValues,
    handleSubmit,
  } = useForm({
    mode: "onChange",
  });
  const onSubmit = (data) => {
    console.log(data)
  };

  return (
    <div className='content'>
      <div className='body'>

        <p className='contacto-info'>Complete el fomulario</p>
        <form onSubmit={handleSubmit(onSubmit)}>

            <div className='formulario-contacto'>
              
              <TextField className='formulario-imput' id="filled-basic" label="Nombre y apellido" variant="filled" {...register("nombre", { required: true, maxLength: 70 })} /><br></br>
              <TextField className='formulario-imput' id="filled-basic" label="Email" variant="filled"   {...register("email", { required: true, maxLength: 30 })} /><br></br>
              <TextField className='formulario-imput' id="filled-basic" label="telefono" variant="filled"  {...register("telefono", { required: true, maxLength: 30 })} /><br></br>
              <TextField
                  className='formulario-imput'
                  id="standard-multiline-static"
                  label="Mensaje"
                  multiline
                  rows={4}
                  variant="standard"
                  {...register("mensaje", { required: true, maxLength: 100 })}
              />

            </div>
            <button type="submit" className="input-send-user"> Enviar</button>

        </form>  
      </div>
    </div>
  )
}
