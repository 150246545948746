import React, { useState,useEffect } from 'react';
import { Modal, Button } from 'rsuite';
import { useSelector, useDispatch } from 'react-redux';
import {buscarProductoF} from "../../actions/buscadorProducto";

import {modificarProducto} from "../../actions/producto"
import { modificarCliente } from '../../actions/clientes';



const EditarProducto=(props)=> {
    const productos = props.productos
    
    if(productos != null){
      
      var obj = JSON.stringify(productos)
      var dataProps = JSON.parse(obj)
     
      var nombre = dataProps.nombre
      var telefono = dataProps.telefono
      var direccion = dataProps.direccion
    
     
    }
    const dispatch = useDispatch();
    const [imput,setImput]=useState({
        nombre:nombre,
        telefono:telefono,
        direccion:direccion,
        
        
    });

    const restric = useSelector(state => state.reducerBusqueda );
    
    const [show, setShow] = useState(productos != null);

    useEffect(() => {
      setShow(productos != null)
      setImput({
        nombre:nombre,
        telefono:telefono,
        direccion:direccion, 
        
      });
      
    }, [productos])

    const valorImput = (e) =>{
      
      
          setImput({
            ...imput,
            [e.target.name]: e.target.value})
          
    }
  
    const handleClose = (e) => {
    
     const item = {
      id :dataProps.id,
      nombre:imput.nombre,
      telefono:Number.parseInt(imput.telefono),
      direccion:imput.direccion,
        }
       
      
     if (imput.nombre == ""  || imput.telefono == ""  || imput.direccion == "") {
           
           
          }else{
            console.log(item.precio, item)
            dispatch(modificarCliente(item))
            let timerId = setTimeout(() => dispatch(buscarProductoF({
              pag:props.pagina,
              filter:props.filter
          })), 3000);
            

            
            setShow(false)
          }
     //if(restric.busqueda_restric==false)  {
     // dispatch(agregarCarro(item))
     // setShow(false)
     }   
      //dispatch(agregarCarro(item))
      //setShow(false)
    
    //};
    const handleCloseButton=()=>{
      setShow(false);
    }
    
    const handleShow = () => setShow(true);

  
    return (
      <>
      {(show) &&
  
        <Modal open={show} onClose={handleClose}>
          <Modal.Header >
            <Modal.Title>Cliente: {nombre}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
              <h3>Nombre:</h3>
              <div className="form-group">
              <input type="text" className="form-control input-sm" onChange={valorImput} value={imput.nombre} name="nombre" min={1}  max={restric.busqueda_restric==true ? props.productos.stock : ""} required ></input>
              </div> 
              <h3>Telefono:</h3>
              <div className="form-group">
              <input type="number" className="form-control input-sm" onChange={valorImput} value={imput.telefono} name="telefono" min={1}  max={restric.busqueda_restric==true ? props.productos.stock : ""} required ></input>
              </div> 
              <h3>Direccion:</h3>
              <div className="form-group">
              <input type="text" className="form-control input-sm" onChange={valorImput} value={imput.direccion} name="direccion" min={1}  max={restric.busqueda_restric==true ? props.productos.stock : ""} required ></input>
              </div>
                                            
          </Modal.Body>
          <Modal.Footer>
            <Button appearance="secondary" onClick={handleCloseButton}>
              Cerrar
            </Button>
            <Button  appearance="primary" type="submit" onClick={handleClose}>
              Editar 
            </Button>
          </Modal.Footer>
        </Modal>
      } 
      </>
    );
  }
  
  export default EditarProducto;