import { combineReducers } from "redux"
import { routerReducer } from "react-router-redux"
import login from './login'
import proveedores from "./proveedores"
import reducerBusqueda from './buscardorProducto'
import tablaPedido from "./tablaPedido"
import cliente from './cliente'
import loader from './loader'
import pedido from './pedido'
import pedidoCheck from './tablaPedidoCheck'
import reducerNotificacion from "./notificacion"
import marca from './marca'

const rootReducer = combineReducers({
    routing: routerReducer,
    login,
    proveedores,
    reducerBusqueda,
    tablaPedido,
    cliente,
    loader,
    pedido,
    pedidoCheck,
    marca,
    reducerNotificacion,
})

export default rootReducer;