import apiInstance from "./api";

export const getMarcas = (user) => {
    return  apiInstance.post('/api/marcas', user)
  
    .then(res => { 
      return res.data;
      
    }).catch(error => {
      console.error(error.response);
      throw error.response
    });
}

export const getAddMarca = (data ) =>{
  return  apiInstance.post('/api/addMarca', data)
  
  .then(res => { 
    return res.data;
    
  }).catch(error => {
    console.error(error.response);
    throw error.response
  });
}
  
export const getUpdateMarca  = (data) =>{
  return  apiInstance.post('/api/updateMarca', data)
  
  .then(res => { 
    return res.data;
    
  }).catch(error => {
    console.error(error.response);
    throw error.response
  });
}