import React, { useEffect } from "react";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import Offcanvas from "react-bootstrap/Offcanvas";
import { Link } from "react-router-dom";
import "./navbar.css";
import { Image, CloudinaryContext } from "cloudinary-react";
import Img from '../../Pages/asset/img/img-bc.png';

export default function NavbarHome() {
    const userLoginToken = localStorage.getItem("token");

    let login = (
        <Nav.Link as={Link} to="login">
            Login
        </Nav.Link>
    );
    const despedir = () => {
        localStorage.clear();
        window.location.href = '/login'; // Realiza la redirección manualmente
    };

    if (userLoginToken != null) {
        login = <Nav.Link as={Link} to="login" onClick={despedir}>Logout</Nav.Link>;
    }

    return (
        <>
            <div className="navbar">
                <Navbar
                    key="sm"
                    bg="light"
                    expand="sm"
                    className="mb-3"
                    fixed="top"
                >
                    <Container fluid>
                        <Navbar.Brand href="/">
                            <div className="igm-nav">
                                {/* <CloudinaryContext cloudName="detdoc8pw">
                                    <Image
                                        publicId="1654099710781_aw4xhd.jpg"
                                        width="100%"
                                    />
                                </CloudinaryContext> */}
                               
                                <a><img  class="mx-auto d-block" src={Img} /></a>  
                                         
                            </div>
                        </Navbar.Brand>

                        <Navbar.Toggle
                            aria-controls={`offcanvasNavbar-expand-sm`}
                        />
                        <Navbar.Offcanvas
                            id={`offcanvasNavbar-expand-xl`}
                            aria-labelledby={`offcanvasNavbarLabel-expand-sm`}
                            placement="end"
                        >
                            <Offcanvas.Header closeButton>
                                <Offcanvas.Title
                                    id={`offcanvasNavbarLabel-expand-sm`}
                                >
                                    BC
                                </Offcanvas.Title>
                            </Offcanvas.Header>
                            <Offcanvas.Body>
                                <Nav className="justify-content-end flex-grow-1 pe-3">
                                    <Nav.Link as={Link} to="/">
                                        Home
                                    </Nav.Link>
                                    { userLoginToken && 
                                    <>
                                        <Nav.Link as={Link} to="pedido">
                                            Pedido
                                        </Nav.Link>
                                        
                                        <Nav.Link as={Link} to="abm">
                                            Abm
                                        </Nav.Link>
                                        <Nav.Link as={Link} to="marcas">
                                            Marcas
                                        </Nav.Link>
                                        <Nav.Link as={Link} to="clientes">
                                            Clientes
                                        </Nav.Link>
                                        <Nav.Link as={Link} to="proveedores">
                                            Proveedores
                                        </Nav.Link>
                                        <Nav.Link as={Link} to="pedidos">
                                            Pedidos
                                        </Nav.Link>
                                    </>
                                    }
                                    {login}
                                </Nav>
                            </Offcanvas.Body>
                        </Navbar.Offcanvas>
                    </Container>
                </Navbar>
            </div>
        </>
    );
}
