import { actionTypes } from "../constants/ActionTypes";

import {store} from '../store/index'

export default (state = store.pedidosCheck, action) => {
    switch (action.type) {
        case actionTypes.SET_CHECK_TABLA_PEDIDO:
            let xProducto = state.pedidos          
            
            if(xProducto.find((element) => element.pedido.id === action.data.pedido.id)){
                const indice = xProducto.findIndex((element) => element.pedido.id === action.data.pedido.id)
                xProducto[indice].stado = action.data.stado
                
            }else{
                xProducto.push(action.data)   
            }
            return Object.assign({}, state, {
                pedidos:  xProducto,
            }) 

        case actionTypes.SET_ALL_TABLA_PEDIDO:
             let xDatito = action.data
              return Object.assign({}, state, {
                pedidos :  xDatito,
            })   
          
                        
        default:
            return state;
        }
}