export const store = {
    login:{
        user_data:null,
    },
    proveedores:{},
    marcas:{},
    busqueda:{
        busqueda_filtro: null,
        busqueda_restric: null,
        busqueda_fecha: null
    },
    tablaPedido:{},
    cliente:{},
    loader: false,
    pedidos: {},
    pedidosCheck: {
        pedidos:[]
    },
    notification:null
}