import apiInstance from "./api";

export const getPedido = (data) => {
    return  apiInstance.post('/api/savePedido', data)
  
    .then(res => { 
      return res.data;
      
    }).catch(error => {
      console.error(error.response);
      throw error.response
    });
  }
  

export  const pedidos = (data) => {
    return  apiInstance.post('/api/listPedidos', data)
  
    .then(res => { 
      return res.data;
      
    }).catch(error => {
      console.error(error.response);
      throw error.response
    });
  }
  