import { actionTypes } from "../constants/ActionTypes";

export function getProveedores(data) {
    return {
        type: actionTypes.FETCH_GET_PROVEEDORES,
        data,
    };
}

export function setProveedores(data){
    return{
        type: actionTypes.SET_PROVEEDORES,
        data,
    }
}

export function agregarProveedor(data){
    return{
        type: actionTypes.ADD_PROVEEDORES,
        data,
    }
}

export function modificarProveedor(data){
    return{
        type: actionTypes.UPDATE_PROVEEDORES,
        data,
    }
}