import { actionTypes } from "../constants/ActionTypes";

export function setLoader(data) {
    return {
        type: actionTypes.SET_LOADER,
        data,
    };
}


