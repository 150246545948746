import { actionTypes } from "../constants/ActionTypes";

export function setNotificacion(data) {
    return {
        type: actionTypes.SET_NOTIFICACION,
        data,
    };
}


export function clearNotificacion() {
    return {
        type: actionTypes.CLEAR_NOTIFICACION,
    };
}