import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import {Col, Card} from 'react-bootstrap';
import { Form, Button} from 'react-bootstrap';
import {buscarProductoF} from "../../actions/buscadorProducto";
import Paginator from 'react-hooks-paginator';
import ModalAgregarCliente from './ModalAgregarCliente'
import TablaClienteABM from './TablaClienteABM'
import { getClientes } from '../../actions/clientes';
import "./cliente.css";

export default function ClienteABM() {
    const data = useSelector((state) => state.reducerBusqueda );
    const clientes = useSelector((state) => state.cliente.cliente?.data);

    const dispatch = useDispatch();
    const [proveedor,setProveedor]=useState(null)
    const [modalAgregar, setModalAgregar] = useState(null)
    const [busqueda,setBusqueda] = useState({
        busquedaDato:''
    });

    const [stateProductos, setStateProductos] = useState([]);
    const [boton,setBoton] = useState(false);
    const [offset, setOffset] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);

    let listProveedores = [];

    const imputDatos = (e) => {
       
        setBusqueda({   
            ...busqueda,
            [e.target.name]: e.target.value});       
    }

    const enviarDatos = (e) => {
       
        setBoton(!boton)
    }
    
    useEffect(() => { 
        const datos={
            pag:currentPage,
            filter:busqueda.busquedaDato
        }   
        dispatch(buscarProductoF(datos) ) 
        
    },[])

    useEffect(() => {  
        
      if(  data.busqueda_filtro != null){
       
        const datos={
            pag:currentPage,
            filter:busqueda.busquedaDato
        }   
        dispatch(buscarProductoF(datos))
      }
        

    },[currentPage]);

    useEffect(() => { 
        
       if (boton != false ){
        const datos={
            pag:currentPage,
            filter:busqueda.busquedaDato
        }   
        if(busqueda.busquedaDato.length >0){ dispatch(buscarProductoF(datos));
            setBoton(false);}
       
       }
       
     },[boton,busqueda]);

     useEffect(() => { 
        if(clientes== null){
            dispatch(getClientes());
            }
    },[clientes])
    
     
  return (
    <div className="content">
        <div className="body">
            
            <Card>    
                <Card.Header>
                <Card.Title as="h5">ABM Cliente</Card.Title>
                    <div className='celular-cliente'>
                        <Col xs={3}>
                            <Form.Control onChange={imputDatos} value={busqueda.busquedaDato} name="busquedaDato" type="text" placeholder=" Ingrese Producto" />
                        </Col>
                        
                        <Form.Group >
                            <Col>
                                <Button style={{margin: "0px 2px 0px" , padding: "6px"}} onClick={ enviarDatos } >Buscar</Button> 
                                <Button variant="primary" type="button" style={{margin: "0px 2px 0px", padding: "6px 10px 6px 10px"}}  onClick={() => setModalAgregar(true)}>+</Button> 
                            </Col>
                        </Form.Group> 

                        <Form.Group >
                            <Col style={{display: "flex"}}>
                            {/* <h5 style={{display: "flex", alignItems: "center" }}>Cliente :</h5> */}
                            {listProveedores}
                            </Col>
                        </Form.Group>  
                    </div>
                </Card.Header>
                <Card.Body>
                    
                     {data.busqueda_filtro != null || stateProductos != null ? <div><TablaClienteABM  clientes={clientes != null ? clientes :  null } productos={stateProductos} pagina={currentPage} filter={busqueda.busquedaDato}></TablaClienteABM></div> : "Ingrese busqueda"}
                        
                    {data.busqueda_filtro!= null   ? 
                    <div> 
                        <Paginator 
                        totalRecords={data.busqueda_filtro.total}
                        pageLimit={3}
                        pageNeighbours={1}
                        setOffset={setOffset}
                        currentPage={currentPage}
                        setCurrentPage={setCurrentPage}
                        
                    /> 
                    </div>: 'no hay paginas'}
                    <ModalAgregarCliente modal={modalAgregar} setModalAgregar={setModalAgregar}/>
                     
                </Card.Body>
            </Card>
    </div>
   </div> 
  );
}
