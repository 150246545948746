import apiInstance from "./api";

export function getCliente (data){
    return  apiInstance.post('/api/clientes', data)
  
    .then(res => { 
      return res.data;
      
    }).catch(error => {
      console.error(error.response);
      throw error.response
    });
  }
  
  

 export function getEliminarProducto (data){
    return  apiInstance.post('/api/deleteProducto', data)
  
    .then(res => { 
      return res.data;
      
    }).catch(error => {
      console.error(error.response);
      throw error.response
    });
  }

  export function getAddCliente (data){
    return  apiInstance.post('/api/addCliente', data)
  
    .then(res => { 
      return res.data;
      
    }).catch(error => {
      console.error(error.response);
      throw error.response
    });
  }
  
  export function getUpdateCliente (data){
    return  apiInstance.post('/api/updateCliente', data)
  
    .then(res => { 
      return res.data;
      
    }).catch(error => {
      console.error(error.response);
      throw error.response
    });
  }
