import { actionTypes } from "../constants/ActionTypes";
import { takeEvery, call, put, take } from 'redux-saga/effects';

import { setMarcas } from "../actions/marca";
import { getAddMarca, getMarcas, getUpdateMarca } from "../api/marca";

export const watchMarcas = function * (){
    yield takeEvery(actionTypes.FETCH_GET_MARCAS, fetchMarcas)
    yield takeEvery(actionTypes.ADD_MARCA, fetchAddMarca)
    yield takeEvery(actionTypes.UPDATE_MARCA, fetchUpdateMarca )
}

function* fetchMarcas(action){
    try{
        const data = yield call(getMarcas, action.data);
        if(data){
            yield put(setMarcas(data));
        }
    }catch(err){
        console.log(err);
        // toast.error('¡Revise los campos!', {
        // });
    }
}

function* fetchAddMarca(action){
    try{
        const data = yield call(getAddMarca, action.data);
        if(data){
            yield put({ type: "FETCH_GET_MARCAS" });
            yield take("SET_MARCAS");
        }
    }catch(err){
        console.log(err);
        // toast.error('¡Revise los campos!', {
        // });
    }
}

function* fetchUpdateMarca(action){
    try{
        const data = yield call(getUpdateMarca, action.data);
        if(data){
            yield put({ type: "FETCH_GET_MARCAS" });
            yield take("SET_MARCAS");
        }
    }catch(err){
        console.log(err);
        // toast.error('¡Revise los campos!', {
        // });
    }
}