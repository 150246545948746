import { actionTypes } from "../constants/ActionTypes";
import { takeEvery, call, put, select, take } from "redux-saga/effects";
import { getPedido, pedidos } from "../api/pedido";
import { setLoader } from "../actions/loader";
import { getProveedores } from "../actions/proveedores";
import { setTablaPedido } from "../actions/tablaPedido";
import { setPedidos } from "../actions/pedido";

export const watchPedido = function* () {
    yield takeEvery(actionTypes.FETCH_GET_ENVIAR_PEDIDO, fetchSavePedido);
    yield takeEvery(actionTypes.FETCH_PEDIDOS, fetchPedido);
};

function* fetchSavePedido(action) {
    try {
        //persiste pedido en bd
        const data = yield call(getPedido, action.data);
        if (data) {
            yield put({ type: "FETCH_GET_MARCAS" });
            yield take("SET_MARCAS");

            yield call(delay, 1000);
            const stateProveedor = yield select(
                (state) => state.marca.marcas
            );
            yield put(setTablaPedido(stateProveedor.Proveedores));
            yield put(setLoader(false));
        }
    } catch (err) {
        console.log(err);
        // toast.error('¡Revise los campos!', {
        // });
    }
}

function* fetchPedido(action) {
    try {
        //persiste pedido en bd
        const data = yield call(pedidos, action.data);
        if (data) {
            yield put(setPedidos(data));
        }
    } catch (err) {
        console.log(err);
        // toast.error('¡Revise los campos!', {
        // });
    }
}

const delay = (ms) => new Promise((res) => setTimeout(res, ms));
