import { actionTypes } from "../constants/ActionTypes";

import {store} from '../store/index'

export default (state = store.notification, action) => {
    switch (action.type) {
        case actionTypes.SET_NOTIFICACION:
        
        return action.data
        
        case actionTypes.CLEAR_NOTIFICACION:
            
        return null;

        default:
            return state;
        }
}