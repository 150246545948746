import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Autoplay, Pagination, Navigation } from "swiper";
import "./home.css";
// import { Image, CloudinaryContext } from "cloudinary-react";
// import { getClientes } from "../../actions/clientes";
// import { getPedidos } from "../../actions/pedido";
import Pancho from '../../Pages/asset/img/perlaPancho.webp'
import superPancho from '../../Pages/asset/img/perlaSuper.webp'
import hambur from '../../Pages/asset/img/hambuPerla.webp'

export default function Home() {
    
    const dispatch = useDispatch();

    const userLoginToken = localStorage.getItem("token");
    const token = useSelector((state) => state.login.user_data);
    const proveedores = useSelector((state) => state.proveedores.proveedores);


    if (token && userLoginToken) {
        console.log(token, proveedores, userLoginToken);
    }

    return (
        <div id="content">
            <div className="body">
                <Swiper
                    spaceBetween={30}
                    centeredSlides={true}
                    autoplay={{
                        delay: 3000,
                        disableOnInteraction: false,
                    }}
                    pagination={{
                        clickable: true,
                    }}
                    navigation={true}
                    modules={[Autoplay, Pagination, Navigation]}
                    className="mySwiper"
                >
                    {/* <SwiperSlide className="swiper-slide">
                        <h1 className='text-slide'>el tony infooooooooooooooooo</h1> 
                        <CloudinaryContext cloudName="detdoc8pw">
                            <Image
                                publicId="1654099710781_aw4xhd.jpg"
                                width="100%"
                            />
                        </CloudinaryContext>
                    </SwiperSlide> */}
                    {/* <SwiperSlide>
                         <h1>holaaaaa mundo!!!!!!!</h1> 
                        <CloudinaryContext cloudName="detdoc8pw">
                            <Image publicId="cld-sample-5.jpg" width="100%" />
                        </CloudinaryContext>
                    </SwiperSlide>
                    <SwiperSlide>
                         <h1>holaaaaa mundo1!!!!!!!</h1> 
                        <CloudinaryContext cloudName="detdoc8pw">
                            <Image publicId="cld-sample-4.jpg" width="100%" />
                        </CloudinaryContext>
                    </SwiperSlide>
                    <SwiperSlide>
                         <h1>holaaaaa mundo!!!!!!!</h1> 
                        <CloudinaryContext cloudName="detdoc8pw">
                            <Image publicId="cld-sample.jpg" width="100%" />
                        </CloudinaryContext>
                    </SwiperSlide> */}
                    <SwiperSlide>
                        <a>
                            <img class="mx-auto d-block" src={Pancho} />
                        </a>
                    </SwiperSlide>
                    <SwiperSlide>
                        <a>
                            <img class="mx-auto d-block" src={superPancho} />
                        </a>
                    </SwiperSlide>
                    <SwiperSlide>
                        <a>
                            <img class="mx-auto d-block" src={hambur} />
                        </a>
                    </SwiperSlide>
                    
                </Swiper>

                <h2>messi</h2>
                <h2>messi</h2>
                <h2>messi</h2>
                <h2>messi</h2>
                <h2>messi</h2>
                <h2>messi</h2>
                <h2>messi</h2>
                <h2>messi</h2>
            </div>
        </div>
    );
}
