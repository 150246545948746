import React, { useState, useEffect } from "react";
import { Modal, Button, Table } from "rsuite";
import { useSelector, useDispatch } from "react-redux";
const { Column, HeaderCell, Cell } = Table;


const ModalProductos = (props) => {
    const productos = props.productos;
    const change = props.changueProducto;

    const dispatch = useDispatch();

    const [show, setShow] = useState(productos != null);
    
    useEffect(() => {
      setShow(productos != null)
    }, [productos])

    const handleClose = (e) => {
            setShow(false);
    };
    
    const handleCloseButton = () => {
        setShow(false);
        change(null)
    };

    const handleShow = () => setShow(true);

    return (
        <>
            {show && (
                <Modal open={show} onClose={handleClose}>
                    <Modal.Header>
                        <Modal.Title>Productos:</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      {productos.length > 0 ?
                        <Table responsive hover
                            height={400}
                            data={productos}
                        >
                            <Column width={60} align="center" fixed>
                                <HeaderCell>Id</HeaderCell>
                                <Cell dataKey="idProducto" />
                            </Column>

                            <Column width={250}>
                                <HeaderCell>Producto</HeaderCell>
                                <Cell dataKey="productoNombre" />
                            </Column>

                            <Column width={150}>
                                <HeaderCell>Cantidad</HeaderCell>
                                <Cell dataKey="cantidad" />
                            </Column>

                            <Column width={100}>
                                <HeaderCell>Cambio</HeaderCell>
                                <Cell dataKey="cambio" />
                            </Column>
                  
                        </Table> : "Pedido sin productos"}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            appearance="secondary"
                            onClick={handleCloseButton}
                        >
                            Cerrar
                        </Button>
                    </Modal.Footer>
                </Modal>
            )}
        </>
    );
};

export default ModalProductos;
